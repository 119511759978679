import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Linkedin, Mail, Github } from 'lucide-react';

const wikipediaLogo = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Wikipedia-logo.png/1024px-Wikipedia-logo.png"; 

const fetchPotd = async () => {
  const today = new Date();
  const dateIso = today.toISOString().split('T')[0];
  const title = `Template:POTD_protected/${dateIso}`;

  const params = new URLSearchParams({
    action: 'query',
    format: 'json',
    formatversion: '2',
    prop: 'images',
    titles: title,
    origin: '*'
  });

  try {
    const response = await fetch(`https://en.wikipedia.org/w/api.php?${params}`);
    const data = await response.json();
    const filename = data.query.pages[0].images[0].title;
    const imageSrc = await fetchImageSrc(filename);

    return {
      filename: filename,
      imageSrc: imageSrc,
      date: today
    };
  } catch (error) {
    console.error('Error fetching POTD:', error);
    return null;
  }
};

const fetchImageSrc = async (filename) => {
  const params = new URLSearchParams({
    action: 'query',
    format: 'json',
    prop: 'imageinfo',
    iiprop: 'url',
    titles: filename,
    origin: '*'
  });

  try {
    const response = await fetch(`https://en.wikipedia.org/w/api.php?${params}`);
    const data = await response.json();
    const page = Object.values(data.query.pages)[0];
    return page.imageinfo[0].url;
  } catch (error) {
    console.error('Error fetching image source:', error);
    return null;
  }
};

const WorkInProgressPage = () => {
  const [wikiImage, setWikiImage] = useState({ filename: '', imageSrc: '', date: null });
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    fetchPotd().then(imageData => {
      if (imageData) {
        setWikiImage(imageData);
        setImageError(false);
      } else {
        setImageError(true);
      }
    });
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        delay: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };

  return (
    <div className="relative min-h-screen overflow-hidden flex flex-col items-center justify-center text-white p-6 pt-24"> {/* Reduced the top padding */}
      {/* Background layers */}
      <div className="background-container">
        <div className="stars"></div>
        <div className="twinkling"></div>
      </div>

      {/* Main Content */}
      <motion.div
        className="text-center max-w-xl relative z-10"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h1 className="text-6xl font-bold mb-3 tracking-tight" variants={itemVariants}>
          Work in Progress...
        </motion.h1>
        <motion.p className="text-2xl mb-10" variants={itemVariants}>
          Exciting projects are underway. Stay tuned for updates!
        </motion.p>

        {/* Wikipedia Image of the Day */}
        <motion.div className="mb-10" variants={itemVariants}>
          <h2 className="text-2xl font-semibold mb-4">Wikimedia Image of the Day</h2>
          {!imageError && wikiImage.imageSrc ? (
            <div className="relative bg-white rounded-xl shadow-lg p-4">
              <img 
                src={wikiImage.imageSrc} 
                alt={wikiImage.filename} 
                className="w-full rounded-lg shadow-lg object-cover"
                onError={() => setImageError(true)}
              />
              <p className="mt-4 text-gray-700 text-lg italic">{wikiImage.filename || 'Image title unavailable'}</p>
              {wikiImage.date && (
                <p className="mt-1 text-gray-600 text-sm">Date: {wikiImage.date.toDateString()}</p>
              )}
            </div>
          ) : (
            <div className="w-full h-64 bg-gray-700 rounded-lg shadow-lg flex items-center justify-center">
              <p>Image unavailable</p>
            </div>
          )}
        </motion.div>

        {/* Social Links */}
        <motion.div className="flex justify-center space-x-6 mb-10" variants={itemVariants}>
          <a href="https://www.linkedin.com/in/ajshul" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400 transition-transform transform hover:scale-125">
            <Linkedin size={32} />
          </a>
          <a href="mailto:shulman.aj@gmail.com" className="hover:text-red-400 transition-transform transform hover:scale-125">
            <Mail size={32} />
          </a>
          <a href="https://github.com/ajshul" target="_blank" rel="noopener noreferrer" className="hover:scale-125">
              <Github size={32} />
          </a>
          <a href="https://en.wikipedia.org/wiki/User:Ajshul" target="_blank" rel="noopener noreferrer" className="hover:scale-125">
            <img src={wikipediaLogo} alt="Wikipedia" className="w-8 h-8" />
          </a>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default WorkInProgressPage;

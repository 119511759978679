import React from 'react';
import WorkInProgressPage from './WorkInProgressPage';

function App() {
  return (
    <div className="App">
      <WorkInProgressPage />
    </div>
  );
}

export default App;